<template>
  <div class="bg-white shadow p-3 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
      Platform Job Roles
    </p>
    <div class="flex flex-row items-end justify-between">
      <div>
        <InputField placeholder="Search by name" label="Filter by" :onChange="setKeyword" leftIcon
          class="mb-2 w-inputField" data-testid="name-field" />
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ filteredList.length }} Job Roles out of {{ jobRoles.length }}
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
        <div class="flex flex-row items-center focus:outline-none w-55 ml-1">
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow max-w-1/4 mr-1">
          <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
            @click="() => sort('jobrolename', 1)">
            <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE NAME</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/4 items-center">
          <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
            @click="() => sort('jobTitleMapping', 2)">
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE MAPPING</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow flex-col max-w-1/5 ml-auto">
          <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
            @click="() => sort('assessment', 3)">
            <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/10 justify-center items-center">
          <button class="flex flex-row items-center focus:outline-none" data-testid="header-1"
            @click="() => sort('status', 4)">
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[4] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
        <div class="flex flex-grow max-w-1/10 justify-center items-center mr-1.5">
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIONS</p>
        </div>
      </div>
      <div>
        <VirtualList style="max-height: 700px; overflow-y: auto" :data-key="'id'" :data-sources="filteredList"
          :data-component="itemComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import { PlaceArrivedFromEnum } from '../../../Products/store/index';
import { mapActions, mapState } from "vuex";

export default {
  name: "JobRolesTable",
  components: { VirtualList },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1, 1],
    mappedList: [],
    pageNr: 1,
  }),
  computed: {
    ...mapState({
      jobRoles: (state) => state.assessmentSetup.jobRolesDescriptions,
    }),
    filteredList() {
      return this.mappedList.filter((item) =>
        item.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
    },
  },
  async mounted() {
    this.setArrivedToProductsEditFrom(PlaceArrivedFromEnum.GLOBAL_JOB_ROLES);
    await this.getJobRolesDescription({ pageNr: 1, pageSz: 999, keyword: this.keyword, assignedOnly: true });
    this.mappedList = this.jobRoles;
  },
  methods: {
    ...mapActions({
      getJobRolesDescription: "assessmentSetup/getJobRolesDescription",
      setArrivedToProductsEditFrom: "products/setArrivedToProductsEditFrom"
    }),
    setKeyword(value) {
      this.keyword = value;
      this.pageNr = 1;
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "number":
          this.mappedList.reverse();
          break;
        case "jobrolename":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "jobTitleMapping":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.jobTitles.localeCompare(b.jobTitles)
          );
          break;
        case "assessment":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.attachedSurveyNames?.[0]?.localeCompare(b.attachedSurveyNames?.[0])
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * (a.isActive - b.isActive)
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
